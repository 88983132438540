/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Link } from '@remix-run/react';
import { images } from '../../constants/images';
import clsx from 'clsx';
import { Navigation } from './navigation';

interface Camp67HeaderProps {
  isLogoLight?: boolean;
  isSunHidden?: boolean;
  isDarkGlowHidden?: boolean;
  isClearBackground?: boolean;
  textColor?: 'light' | 'dark';
  logoAppearance?: 'light' | 'normal' | 'dark';
  appearance?: 'subtle' | 'normal';
  hasBanner?: boolean;
}

export const Camp67Header: React.FC<Camp67HeaderProps> = ({
  isLogoLight = false,
  isSunHidden = false,
  isDarkGlowHidden = false,
  textColor = 'light',
  isClearBackground = false,
  appearance = 'normal',
  logoAppearance = 'normal',
  hasBanner = true,
}) => {
  const isNormalAppearance = appearance === 'normal';

  return (
    <header
      className={clsx('w-full flex flex-row items-center pb-4 sm:pb-0 relative', {
        'md:block pt-6 justify-between': isNormalAppearance,
        'h-24 sm:h-32 justify-between md:justify-center': !isNormalAppearance,
        'mt-9': hasBanner,
      })}
    >
      {!isDarkGlowHidden && (
        <div className="absolute bg-gradient-to-b from-[black]/65 from-0% to-[#FFF]/0 to-[45%] h-[85vh] w-full top-0 " />
      )}
      <div
        className={clsx(
          'm-[unset] rounded-full z-10 flex flex-col items-center justify-center',
          {
            'bg-gradient-to-b from-[#DBFF00] from-20% to-white/0 to-90%':
              !isSunHidden && isNormalAppearance,
            'relative md:m-auto ml-6 w-20 h-20 md:h-28 md:w-28': isNormalAppearance,
            'md:left-0 md:absolute w-[unset] md:top-1/2 md:-translate-y-1/2':
              !isNormalAppearance,
          },
        )}
      >
        <Link prefetch="intent" to="/">
          <img
            loading="lazy"
            alt="The logo of camp67, a Kumbh campsite in Prayagraj, India."
            src={
              logoAppearance === 'dark'
                ? images['logo-dark']
                : !isLogoLight
                  ? images['logo-grey']
                  : images['logo-white']
            }
            className={clsx('m-auto select-none pointer-events-none', {
              'w-12 md:w-16': logoAppearance !== 'dark',
              'w-16': logoAppearance === 'dark',
            })}
          />
        </Link>
      </div>
      <Navigation
        textColor={textColor}
        isClearBackground={isClearBackground}
        headerAppearance={appearance}
      />
    </header>
  );
};

export const Camp67CompactHeader: React.FC<Camp67HeaderProps> = () => {
  return (
    <header className={clsx('w-full flex flex-row items-center relative h-12')}>
      <div
        className={clsx(
          'm-[unset] rounded-full z-10 flex flex-col items-center justify-center',
        )}
      >
        <Link prefetch="intent" to="/">
          <p className="uppercase tracking-wider text-sm"> &lt; Return to home</p>
        </Link>
        <Link
          prefetch="intent"
          to="/"
          className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        >
          <img
            loading="lazy"
            alt="The logo of camp67, a Kumbh campsite in Prayagraj, India."
            src={images['logo-dark']}
            className={clsx('m-auto select-none pointer-events-none w-10')}
          />
        </Link>
      </div>
    </header>
  );
};
